/**
 * @deprecated Use string instead
 *  keywords for public api
 * */
const AlertVariants = {
  SUCCESS: 'success',
  DANGER: 'danger',
  INFO: 'info',
  WARNING: 'warning',
  DEFAULT: 'default',
} as const;

export { AlertVariants };

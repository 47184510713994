'use client';
import { useEffect } from 'react';

import { useParams, usePathname, useRouter } from 'next/navigation';

import { useUserInfo } from '@/layers/core/deal/services/useUserInfo';

/** Force user to navigate to the page that makes it change password */
export default function VerifyRequiresNewPass() {
  const path = usePathname();
  const { push } = useRouter();
  const { data } = useUserInfo();
  const reset = !!data && data.reset_password;
  const p = useParams();
  const lang = p?.lang;

  useEffect(() => {
    if (
      reset &&
      path !== null &&
      !['/', path].includes(`/${lang}/account/changePwd`)
    )
      push(`/${lang}/account/changePwd`);
  }, [reset, lang, push, path]);

  return null;
}

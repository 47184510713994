import { AlertVariants } from '../data';

import { ReactComponent as Danger } from '@quick/components/img/danger.svg';
import { ReactComponent as Info } from '@quick/components/img/info.svg';
import { ReactComponent as Success } from '@quick/components/img/success.svg';
import { ReactComponent as Warning } from '@quick/components/img/warning.svg';

/* Select component for DEFAULT key */
const alertTypes = {
  [AlertVariants.SUCCESS]: Success,
  [AlertVariants.DANGER]: Danger,
  [AlertVariants.INFO]: Info,
  [AlertVariants.WARNING]: Warning,
  [AlertVariants.DEFAULT]: Success,
};

export { alertTypes };

'use client';

import 'core-js/features/array/to-reversed';
import 'core-js/features/array/to-spliced';
import 'core-js/features/array/to-sorted';
import 'core-js/features/array/with';

import type { ReactNode } from 'react';

import { TooltipProvider } from '@radix-ui/react-tooltip';
import { useReducedMotion } from '@react-spring/web';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Provider } from 'jotai';

import { Toaster } from '@quick/components/Toaster';

import {
  AppHealthCheckAlert,
  Boundary,
  Clarity,
  CookieNotice,
  CompatibilityNotice,
  DetectDVH,
  EnvTag,
  FontConfig,
  LangProvider,
  ThemeDetector,
  useValidateToken,
} from '@/components/core/AppLayout';
import VerifyRequiresNewPass from '@/components/core/AppLayout/VerifyRequiresNewPass';
import { NoSSR } from '@/components/core/NoSSR';
import { CountryProvider } from '@/layers/core/deal/services/CountryContext';

/// <reference types="dayjs/plugin/utc" />
/// <reference types="dayjs/plugin/timezone" />

dayjs.extend(utc);
dayjs.extend(timezone);

export function HooksCaller({ children }: { children: ReactNode }) {
  useValidateToken();
  useReducedMotion();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export function ClientProviders({
  children,
  lang,
}: {
  children: ReactNode;
  lang?: string;
}) {
  return (
    <Provider>
      <CountryProvider>
        <TooltipProvider>
          <HooksCaller>
            <CountryProvider>
              <DetectDVH />
              <FontConfig />
              <ThemeDetector />
              <Clarity />
              <Boundary>
                <LangProvider value={lang}>
                  <VerifyRequiresNewPass />
                  <AppHealthCheckAlert />
                  {children}
                  <NoSSR>
                    <CookieNotice />
                    <CompatibilityNotice />
                  </NoSSR>
                </LangProvider>
              </Boundary>
              <EnvTag />
              <Toaster />
            </CountryProvider>
          </HooksCaller>
          <Toaster />
        </TooltipProvider>
      </CountryProvider>
    </Provider>
  );
}

import dynamic from 'next/dynamic';

interface INoSSR {
  children: React.ReactNode;
  // ssr?: React.ReactNode;
}

const NonSSRWrapper = ({ children }: INoSSR) => children;

const NoSSR = dynamic(() => Promise.resolve(NonSSRWrapper), {
  ssr: false,
});

export { NoSSR };
export default NoSSR;
